.movie-carousel-container {
  width: 100vw;
  background-color: black;
}

.movie-poster {
  height: 300px;

}

.movie-poster > image {
  height: 100%;
  width: 100%;
}

.movie-card-container {
  height: 550px;
  background-color: black;
}

.movie-card {
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1)), var(--img);

}

.movie-detail {
  position: absolute;
  top: 200px;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.movie-title{
  color:white;
  display: flex;
  align-items: center;
}

.movie-buttons-container{
  display:flex;
  justify-content: space-between;
  align-items: center;
  width:500px;
}

.play-button-icon-container{
  padding:0px;
  margin:0px;
  width:150px;
}
.play-button-icon{
  padding:10px;
  margin:0px;
  color:rgb(145, 33, 171);
  font-size: 2.5rem;
  transition: 0.3s;
  cursor: pointer;
}
.play-button-icon:hover{
  font-size: 4rem;
  color:white;
}

.movie-review-button-container{
  display:flex;
  justify-content: space-between;
  align-items: center;
  width:500px;
  /* color:blue; */
}

@media only screen and (max-width: 800px) {
  .movie-detail{
      flex-direction: column;
      align-items: center;
      top:20px;
  }
}
