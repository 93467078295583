@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

* {
  /* padding: 0;
  margin: 0; */
  box-sizing: border-box;
  font-family: 'Quicksand' Arial, Helvetica, sans-serif;
}

body {
  padding: 0;
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: rgb(22, 21, 21);
  color: whitesmoke;
  margin-top: 10px;
}
